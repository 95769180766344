import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';

import { Title } from 'components/text';
import { useSecretInfoForm } from './useSecretInfo.form';

export const SecretInfoForm = ({ buttonClicked, clearAfterSubmit, title, info, autocompleteOptions, buttonText }) => {
  const { secretName, secretValue, handleRecipient, handleSecretName, handleSecretValue, formContent, onSubmit, isFilled, StyledTooltip } =
    useSecretInfoForm(buttonClicked, clearAfterSubmit);

  return (
    <>
      <Grid container item spacing={2} justifyContent="center">
        <Grid item xs={8} marginTop={1.65} marginBottom={3}>
          <Title>{title}</Title>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <StyledTooltip title={info}>
            <InfoIcon color="primary" sx={{ marginTop: 2 }} />
          </StyledTooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2} item direction="column" justifyContent="space-between">
        <Grid item xs={3}>
          <Grid item xs={12}>
            <Box display="flex" sx={{ pb: 2 }}>
              <Autocomplete
                disablePortal
                id="combo-box-recipient"
                options={autocompleteOptions || []}
                renderInput={(params) => <TextField {...params} label={formContent.recipientLabel} helperText={formContent.recipientHelperText} />}
                fullWidth
                onChange={(_e, value) => {
                  handleRecipient(value);
                }}
              />
            </Box>
          </Grid>
          <Divider />
        </Grid>

        <Grid item xs={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <TextField
                id="secret-name"
                label={formContent.secretNameLabel}
                fullWidth
                required
                onChange={(e) => {
                  handleSecretName(e.target.value);
                }}
                value={secretName}
                helperText={formContent.secretNameHelperText}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <TextField
                id="secret-value"
                label={formContent.secretValueLabel}
                fullWidth
                required
                onChange={(e) => {
                  handleSecretValue(e.target.value);
                }}
                value={secretValue}
                helperText={formContent.secretValueHelperText}
              />
            </Box>
          </Grid>
          <Grid item xs={12} paddingTop={1.25}>
            <Button id="add-secret-btn" onClick={onSubmit} color="primary" disabled={!isFilled()}>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

SecretInfoForm.defaultProps = {
  autocompleteOptions: [],
};

SecretInfoForm.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  autocompleteOptions: PropTypes.instanceOf(Array),
  buttonText: PropTypes.string.isRequired,
  buttonClicked: PropTypes.func.isRequired,
  clearAfterSubmit: PropTypes.bool.isRequired,
};

export default SecretInfoForm;
