import { useContext } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import KeyIcon from '@mui/icons-material/Key';

import { Title } from 'components/text';
import { TableView } from 'components/controls';
import { LanguageContext } from 'context';

export const SecretsBreakdown = ({ secretsToShare, onButtonClick, currentUserPublicKey }) => {
  const { lang } = useContext(LanguageContext);

  return (
    <Box>
      <Grid item xs={12}>
        <TableView
          tableName="share-secrets"
          pagination={secretsToShare.length > 4 && true}
          rowsPerPageOptions={[5]}
          headerContent={
            <Title>
              {lang?.shareSecrets.secretsReadyToShare.currentPublicKey}{' '}
              <Chip icon={<KeyIcon />} label={currentUserPublicKey || lang?.shareSecrets.emptyPublicKey} color="secondary" variant="outlined" />
            </Title>
          }
          maxRows={5}
          tableTitles={[
            { text: lang?.shareSecrets.recipient, mapping: 'recipient' },
            { text: lang?.shareSecrets.secretName, mapping: 'name' },
            {
              text: lang?.shareSecrets.secretValue,
              mapping: 'secret',
              obfuscated: true,
            },
          ]}
          tableContent={secretsToShare}
          title={lang?.shareSecrets.secretsReadyToShare.title}
          selectable={false}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <Button id="share-secret-btn" onClick={onButtonClick} disabled={!secretsToShare?.length > 0}>
            {lang?.shareSecrets.secretsReadyToShare.shareSecrets}
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

SecretsBreakdown.propTypes = {
  secretsToShare: PropTypes.instanceOf(Array).isRequired,
  onButtonClick: PropTypes.func.isRequired,
  currentUserPublicKey: PropTypes.string,
};

SecretsBreakdown.defaultProps = {
  currentUserPublicKey: null,
};

export default SecretsBreakdown;
