import axios from 'axios';
import { EnvManager } from 'config';

const baseUrl = `${EnvManager.AUTH_URL}/authn`;

const logoutUser = async () => {
  try {
    const response = await axios.get(`${baseUrl}/logout`, {
      withCredentials: true,
    });
    return response?.data;
  } catch (error) {
    return null;
  }
};

export const logoutUserSession = async () => {
  const didLogout = await logoutUser();
  sessionStorage.clear();
  return Boolean(didLogout);
};

export default logoutUserSession;
