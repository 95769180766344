import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { IconMapping } from 'components/mappings';

import { EnvManager } from 'config';
import { LanguageContext } from 'context';
import { useAuth } from 'hooks';
import { LoadingSection } from 'components/sections';

const loginUrl = `${EnvManager.AUTH_URL}/authn/login/secureStore`;

export const Login = () => {
  const GoogleIcon = IconMapping.Google;
  const { lang } = React.useContext(LanguageContext);

  const { activeUser, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeUser) {
      navigate('/dashboard');
    }
  }, [activeUser, navigate]);

  return isLoading ? (
    <LoadingSection />
  ) : (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
      }}
    >
      <Grid item xs={3}>
        <Paper>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
            spacing={1}
            p={2}
            style={{
              minHeight: '60vh',
              maxWidth: '70vw',
              minWidth: '20vw',
            }}
          >
            <Grid item xs={12}>
              <img src="logo.png" alt="Secure Store logo" width={150} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h2" color="primary" align="center" gutterBottom>
                {lang?.siteTitle}
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ pb: 2 }}>
              <Button id="login-btn" variant="contained" size="large" href={loginUrl} endIcon={<GoogleIcon />}>
                {lang?.login.buttonText}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
